import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import Fennec from "../components/fennec"
import SEO from "../components/seo"
import { getRandomIntInclusive } from "../util/mathUtils"

export default function FennecTemplate({ data: { fennec, allFennec } }: PageProps<QueryData>) {
  const currFennec = parseInt(fennec.parent.name, 10)

  let nextFennec = 1

  do {
    // fennecs are numbered inclusively starting at `1.jpg`
    nextFennec = getRandomIntInclusive(1, parseInt(allFennec.totalCount, 10))
    // but we should keep generating until we get a new one that isn't the same as the old one
  } while (nextFennec == currFennec)

  return (
    <Layout>
      <SEO title={`#${fennec.parent.name}`} image={fennec.parent.childImageSharp.fixed.src} />

      <Fennec color={fennec.colors.muted} image={fennec.parent.childImageSharp.fixed.src} nextFennec={nextFennec} />
    </Layout>
  )
}

interface QueryData {
  allFennec: {
    totalCount: string
  }
  fennec: {
    parent: {
      name: string
      childImageSharp: {
        fixed: {
          src: string
        }
      }
    }
    colors: {
      vibrant: string
      muted: string
      lightVibrant: string
      lightMuted: string
      darkVibrant: string
      darkMuted: string
    }
  }
}

export const query = graphql`
  query($id: String!) {
    allFennec {
      totalCount
    }
    fennec(id: { eq: $id }) {
      parent {
        ... on File {
          name
          childImageSharp {
            fixed(toFormat: PNG, width: 1000) {
              src
            }
          }
        }
      }
      colors {
        vibrant
        muted
        lightVibrant
        lightMuted
        darkVibrant
        darkMuted
      }
    }
  }
`
