import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

export interface FennecProps {
  color: string
  image: string
  nextFennec: number
}

export default function Fennec({ color, image, nextFennec }: FennecProps) {
  return (
    <>
      {/* set background color to the image's avg color */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
          body {
            background-color: ${color};
          }`,
        }}
      />

      <Link to={`/${nextFennec}`}>
        <img src={image} alt="fennec" />
      </Link>
    </>
  )
}
